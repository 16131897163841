import { LoggingServicePort } from '../../ports'
import { sendLog } from '@otto-ec/global-resources/debug'

/**
 *
 */
export class LoggingServiceGlobalResourcesAdapter
  implements LoggingServicePort
{
  private static readonly NAMESPACE_CONSENT_BANNER = 'eprivacy_consentBanner'

  /**
 *
 *
 *
 *
 *
 */
  logMessage(message: string, id?: string) {
    const trimmedMessage = this.trimMessage(message)
    if (!trimmedMessage || trimmedMessage.length === 0) {
      return
    }
    const data = {
      message: trimmedMessage,
      ...(id ? { id: this.trimMessage(id) } : {}),
    }
    void sendLog(
      LoggingServiceGlobalResourcesAdapter.NAMESPACE_CONSENT_BANNER,
      data
    )
  }

  logError(error: unknown) {
    if (!(error instanceof Error)) {
      try {
        const errorAsString = JSON.stringify(error)
        this.logMessage(
          'Error argument in loggingService.logError is not of type Error: ' +
            errorAsString
        )
      } catch (e) {
        console.warn('Could not stringify error', e, error)
      }
      return
    }
    const message = this.trimMessage(error.message)
    if (!message || message.length === 0) {
      return
    }
    const data = {
      message: message,
      stack: this.trimMessage(error.stack, 256),
      errorName: error.name,
    }
    void sendLog(
      LoggingServiceGlobalResourcesAdapter.NAMESPACE_CONSENT_BANNER,
      data
    )
  }

  private trimMessage(message?: string, numChars: number = 128) {
    return message?.substring(0, numChars).trim()
  }
}

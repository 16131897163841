import { GlobalOnexConfig } from '@onex/model'
import { config as liveConfig } from './onex-config'

export const config: GlobalOnexConfig = {
  ...liveConfig,
  active: false,
  activeE2eTests: false,
  activeOnexConfig: {
    ...liveConfig.activeOnexConfig,
  },
}

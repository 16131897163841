/*                                                                                      */
import './hide-banner.css'
import { ConsentBannerVisibilityPort } from '../../ports'

export const HIDE_OT_CONSENT_BANNER_CLASS = 'eprivacy-ot-cookie-banner--hidden'

export const visibilityAdapter: ConsentBannerVisibilityPort = {
  showConsentBanner() {
    window.document.body.classList.remove(HIDE_OT_CONSENT_BANNER_CLASS)
  },

  hideConsentBanner() {
    window.document.body.classList.add(HIDE_OT_CONSENT_BANNER_CLASS)
  },
}

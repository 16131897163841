import {
  activeOnexAssignmentAdapter,
  ActiveOnexConfigAdapter,
  appsAdapter,
  CmpAdapter,
  environmentConfigAdapter,
  EventQBusAdapter,
  featureTogglesAssetsAdapter,
  LoggingServiceGlobalResourcesAdapter,
  ModuleLoaderAdapter,
} from './adapters'
import {
  ActiveOnexConfigPort,
  AppsPort,
  CmpImpl,
  EventBusPort,
  FeatureTogglePort,
  LoggingServicePort,
} from './ports'
import { config } from '@onex/config'
import { config as configNonlive } from '@onex/config-nonlive'
import { OnexConfig } from '@onex/model'

export const environmentConfig = environmentConfigAdapter()
export const featureToggles: FeatureTogglePort = featureTogglesAssetsAdapter
export const eventBus: EventBusPort = new EventQBusAdapter()
export const moduleLoader = new ModuleLoaderAdapter()
export const logger: LoggingServicePort =
  new LoggingServiceGlobalResourcesAdapter()
export const cmp: CmpImpl = new CmpAdapter().cmp()
export const apps: AppsPort = appsAdapter

const onexConfig: ActiveOnexConfigPort = new ActiveOnexConfigAdapter(
  logger,
  activeOnexAssignmentAdapter,
  environmentConfig.stage === 'live' ? config : configNonlive
)
export const activeVariantConfig: Promise<OnexConfig> =
  onexConfig.activeOnexConfig()

import {
  AppsPort,
  ConsentBannerVisibilityPort,
  LoggingServicePort,
} from '../../ports'

export const O_APPS_NOT_DEFINED_ERROR_ID = 'EPR.CB.OAPPS-NOT-DEFINED.1'

export class ShowConsentBannerIfNotAppOrAppDecidesUseCase {
  constructor(
    private readonly visibilityPort: ConsentBannerVisibilityPort,
    private readonly appsPort: AppsPort,
    private readonly loggingService: LoggingServicePort
  ) {}

  hideInitial() {
    this.visibilityPort.hideConsentBanner()
  }

  async run() {
    if (!this.appsPort.isApp()) {
      this.visibilityPort.showConsentBanner()
      return
    }
    if (typeof this.appsPort.apps() === 'undefined') {
      this.loggingService.logMessage(
        'o_apps is not defined. Retry in 1000ms.',
        O_APPS_NOT_DEFINED_ERROR_ID
      )
      await new Promise((resolve) => setTimeout(resolve, 1000))
      /*          */
      if (typeof this.appsPort.apps() === 'undefined') {
        this.loggingService.logMessage(
          'o_apps is not defined. o_apps is not defined after retry. Keep banner hidden.',
          O_APPS_NOT_DEFINED_ERROR_ID
        )
        console.error(
          'oneTrustCookieBanner. o_apps is not defined. Keep banner hidden.'
        )
        return
      }
    }

    if (this.appsPort.apps()!.shouldShowWebCookieBanner()) {
      this.visibilityPort.showConsentBanner()
    }
  }
}

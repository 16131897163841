import { ShowConsentBannerIfNotAppOrAppDecidesUseCase } from './core'
import { visibilityAdapter } from './adapters'
import { apps, logger } from './singletons'

export const showConsentBannerIfNotAppOrAppDecidesUseCase =
  new ShowConsentBannerIfNotAppOrAppDecidesUseCase(
    visibilityAdapter,
    apps,
    logger
  )

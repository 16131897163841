import { GlobalOnexConfig } from '@onex/model'

export const config: GlobalOnexConfig = {
  active: true,
  id: 'E3280',
  activeEdgeLambda: false,
  smoketestsIncludeOnex: false,
  activeE2eTests: false,
  activeOnexConfig: {
    StatusQuo: {
      template: 'cookieBanner-onetrust.html',
      styleFile: 'styles-onetrust.scss',
    },
    NewConsB: {
      styleFile: 'styles-onetrust--simple-cookie.scss',
    },
    NewConsC: {
      styleFile: 'styles-onetrust--great-buttons.scss',
    },
    NewConsD: {
      styleFile: 'styles-onetrust--icons-left.scss',
    },
    NewConsE: {
      styleFile: 'styles-onetrust--icons-right.scss',
    },
    NewConsF: {
      styleFile: 'styles-onetrust--blue-button.scss',
    },
    NewConsG: {
      styleFile: 'styles-onetrust--green-button.scss',
    },
  },
}
